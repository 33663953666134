import React, { useState } from 'react';

import Layout from '../Layout';
import Image from '../Image';

import styles from './Banner.module.scss';

const Banner: React.FC = () => {
  return (
    <Layout.FullWidth
      className={styles.banner}
      data-algolia-remove='true'
      withSeparator
    >
      <div className={styles.container}>
        <div className={styles.hero}>
          <h1 className={styles.title}>Welcome to the Docs</h1>
          <h4 className={styles.subtitle}>
            Here you'll find guides, resources, and references to build with
            Plaid.
          </h4>
          <Layout.Spacer units={5} />
        </div>
        <div className={styles.imageContainer}>
          <Image
            className={styles.heroPoster}
            src='/assets/img/hero/docs-lincoln.png'
            alt='Decorative image of a Abe Lincoln enjoying an energy drink'
          />
        </div>
      </div>
    </Layout.FullWidth>
  );
};

Banner.displayName = 'Banner';

export default Banner;
